import "./styles/styles.scss";

import "@stimulus/polyfills"
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import turbolinks from "turbolinks";
import "lazysizes";
const application = Application.start();
const context = require.context("./controllers", true, /\.js$/);
application.load(definitionsFromContext(context));
turbolinks.start();

document.addEventListener('lazybeforeunveil', function(e){
    var bg = e.target.getAttribute('data-bg');
    if(bg){
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});
