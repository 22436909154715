import { Controller } from 'stimulus'
import elasticlunr from 'elasticlunr'

export default class extends Controller {
  static targets = [ 'input', 'results', 'feedback' ]

  connect () {
    fetch('/search-index.json').then(resp => {
      resp.json().then(rawIndex => {
        this.searchIndex = elasticlunr.Index.load(rawIndex)

        let searchParams = new URLSearchParams(location.search)
        let queryParam = searchParams.get('q')

        if (queryParam) {
          this.inputTarget.value = queryParam
        }

        if (this.inputTarget.value) {
          this.inputChanged(this.inputTarget)
        }
      })
    })
  }

  inputChanged (e) {
    let value = e.value || e.target.value
    let results = this.searchIndex.search(value, {
      field: {
        title: {
          boost: 3
        },
        subtitle: {
          boost: 2
        },
        description: {
          boost: 1
        }
      }
    })

    this.resultsTarget.innerHTML = ""
    this.feedbackTarget.innerHTML = ""

    let resultCount = results?.length || 0

    if (resultCount > 0) {
      results.map(result => {
        this.resultsTarget.appendChild(this.resultEl(this.searchIndex.documentStore.docs[result.ref]))
      })

      if (resultCount > 1) {
        this.feedbackTarget.innerHTML = `${resultCount} results found`
      } else if (resultCount == 1) {
        this.feedbackTarget.innerHTML = `1 result found`
      }
    }
  }

  resultEl(doc) {
    let li = document.createElement('li')
    li.classList.add("mb-24")
    li.innerHTML = `<a class="text-lg" href="${doc.id}">${doc.title}</a>
    <div class="font-semibold">${doc.subtitle}</div>
    <p class="mt-2 opacity-80">${doc.excerpt}</p>
    <div class="tag-sm float-left mt-2 inline-block ${doc.breadcrumbs?.[0]?.name ? '' : 'hidden'}">${doc.breadcrumbs?.[0]?.name || ""}</div>
    `
    return li
  }
}
