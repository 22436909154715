import { Controller } from 'stimulus'
import { storeGet, storeSet, storeRemove } from '../utils/storage'

export default class extends Controller {
  initialize () {
    this.element[this.attr] = this.storeValue
  }

  update (e) {
    let elAttrValue = e?.target?.[this.attr] || this.element[this.attr]

    // Remove from stor if value is the same as the default
    if (`${elAttrValue}` === this.default) {
      storeRemove(this.key)
    } else {
      storeSet(elAttrValue, this.key)
    }
  }

  get attr() {  // e.g. value or checked
    return this.data.get('attr') || "value"
  }

  get default() {  // default value to set if not in storage
    return this.data.get('default')
  }

  get key() {   // what to persist this as in storage
    return this.data.get('key')
  }

  get storeValue() {
    let v = storeGet(this.key)
    if (v === "true") {
      return true
    } else if (v === "false") {
      return false
    } else {
      return v || this.default
    }
  }
}
