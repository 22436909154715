import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'filter', 'item', 'feedback', 'results' ]

  initialize () {
    this.filterContainer = this.filterTargets[0].parentElement
    this.filterContainer.classList.add('opacity-0')
    this.filterContainer.classList.add('transition-all')
    this.filterContainer.classList.add('duration-500')
    this.filterContainer.classList.add('ease-in-out')
  }

  connect () {
    setTimeout(() => {
      this.refresh()
      this.filterContainer.classList.remove('opacity-0')
    }, 10)
  }

  selectAll (e) {
    this.filterTargets.forEach(el => {
      let input = this.inputForEl(el)
      input.checked = true
      this.storeableControllerFor(input).update()
    })

    this.refresh()
  }

  deselectAll (e) {
    this.filterTargets.forEach(el => {
      let input = this.inputForEl(el)
      input.checked = false
      this.storeableControllerFor(input).update()
    })

    this.refresh()
  }

  inputForEl(el) {
    return el.getElementsByTagName('input')[0]
  }

  tagIdsForItem(el) {
    return el.dataset["tagIds"].split(',').map(it => `${it}`)
  }

  refresh () {
    let activeTags = this.filteredTagIds
    this.filterTargets.forEach(el => el.classList.toggle('active', this.inputForEl(el).checked))

    this.itemTargets.forEach(el => {
      let tags = this.tagIdsForItem(el)
      let isActive = activeTags.some(filteredTagId => tags.includes(filteredTagId))

      el.classList.toggle('hidden', !isActive)
    })

    let resultCount = this.itemTargets.filter(el => !el.classList.contains('hidden')).length

    if (resultCount > 1) {
      this.resultsTarget.innerHTML = `${resultCount} resources found`
    } else if (resultCount == 1) {
      this.resultsTarget.innerHTML = `1 resource found`
    } else {
      this.resultsTarget.innerHTML = `No resources found. Try using less filters`
    }
  }

  toggleTag (e) {
    this.refresh()
  }

  storeableControllerFor(storeable) {
    return this.application.getControllerForElementAndIdentifier(
      storeable,
      "storeable"
    )
  }

  get filteredTagIds () {
    return this.filterTargets.map(el => this.inputForEl(el)).filter(input => input.checked).map(input => input.value)
  }
}
