const PRINTABLES = require("../../site/_data/printables.json");
const SKILLS = require("../../site/_data/printableSkills.json");
const THEMES = require("../../site/_data/printableThemes.json");

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'results', 'submit', 'theme', 'skill', 'feedback', 'count' ]
  connect () {
    let searchParams = new URLSearchParams(location.search)

    let skillIDParam = searchParams.get('skill_id')
    let themeIDParam = searchParams.get('theme_id')

    if (skillIDParam && themeIDParam) {
      this.skillTarget.value = skillIDParam
      this.themeTarget.value = themeIDParam
    }

    this.refresh()
  }

  addResult(printable, skillName, themeName) {
    this.resultsTarget.appendChild(this.resultEl(printable, skillName, themeName))
  }

  clearResults() {
    this.resultsTarget.innerHTML = ""
  }

  refresh () {
    this.clearResults()

    let skillID = this.skillTarget.value
    let themeID = this.themeTarget.value

    let skillName = this.lookup("skill", skillID)?.name
    let themeName = this.lookup("theme", themeID)?.name

    let count = Number(this.countTarget.value)

    let filteredPrintables = PRINTABLES.filter( it => it.skill_id == skillID && it.theme_id == themeID)

    let len = filteredPrintables.length,
      taken = new Array(len);
    if (count > len) {
      // Add all
      filteredPrintables.forEach(it => this.addResult(it, skillName, themeName))
    } else {
      while (count--) {
        let x = Math.floor(Math.random() * len);
        this.addResult(filteredPrintables[x in taken ? taken[x] : x], skillName, themeName)
        taken[x] = --len in taken ? taken[len] : len;
      }
    }

    this.feedbackTarget.innerHTML = `${skillName} - ${themeName} - ${this.resultsTarget.children.length} results`
  }

  generate(e) {
    this.refresh()
  }

  lookup(type, id) {
    if (type === "skill") {
      return SKILLS.find( it => it.id == id)
    } else if (type === "theme") {
      return THEMES.find( it => it.id == id)
    }
  }

  printableUrl(printable) {
    let idPartition = String(printable.id).padStart(9, 0).match(/.{1,3}/g).join("/")
    return `https://assets.infiniteach.com/printables/${idPartition}/${printable.file_name}`
  }

  resultEl(printable, skillName, themeName) {
    let li = document.createElement('li')
    li.dataset['url'] = this.printableUrl(printable)
    li.classList="mb-2 flex flex-row flex-wrap justify-start items-center"
    li.innerHTML = `
      <a class="truncate mr-2 bg-gray-100 p-3 rounded-lg" href="${this.printableUrl(printable)}" target="_blank">${skillName}: ${themeName} (#${printable.name}) <span class="inline-block"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" stroke="currentColor" stroke-width="2" class="w-4 h-4"><path d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"/></svg></span></a>
    `
    return li
  }

  urlForEvent(e) {
    return e.target.parentElement.parentElement.parentElement.dataset.url
  }
}
